import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { Subscription } from 'rxjs';
import { ReportPresentationOrder, ReportOuput, MeetingValidity, ReportTypes, ReportCustomProperties, MeetingGeneralType, PollInterval, EventType } from '../../shared/models/assemblea/enums';
import { vEventoAssemblea } from '../../shared/models/assemblea/vEventoAssemblea';
import { ReportRequest, ReportParameters } from '../../shared/models/assemblea/ReportRequest';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { SysConfigItems } from '../../shared/models/assemblea/SysConfigItems';
import { LogService } from '../../shared/services/log.service';
import { AssembleaError } from '../../shared/models/assemblea/AssembleaError';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent } from '../../shared/components/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { VoteCard } from '../../shared/models/assemblea/VoteCard';
import { PrintPageConfiguration, PrintEventConfiguration } from '../../shared/models/assemblea/PrintConfiguration';
import { StorageService } from '../../shared/services/storage.service';
import { isnull, asyncForEach, isNullOrUndefined } from '../../shared/utils/utils';
import notify from 'devextreme/ui/notify';
import { PrintingService } from '../../shared/services/printing.service';


class ViewConfigs {
  MeetingGeneralType: number = -1;
  HideOvercustomizedReports: boolean;
  CoopAlleanza: boolean;
  DenyExitWithPrevote: boolean;
}

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.scss']
})
/** PrintPage component*/
export class PrintPageComponent implements OnDestroy {  
  subscriptions: Subscription = new Subscription();
  configs: ViewConfigs = new ViewConfigs();
  InError: boolean = false;

  SelectedVoteCards: VoteCard[] = [];
  AssembleaVoteCards: VoteCard[] = [];


  ShareholderOrderByOptions: any[] = [
    { Value: ReportPresentationOrder.ORDER_ALFABETICO, Text: "Nome / Ragione sociale " },
    { Value: ReportPresentationOrder.ORDER_CODICE, Text: "Codice Socio / Ospite" },
    { Value: ReportPresentationOrder.ORDER_ORA_MOVIMENTO, Text: "Orario" },
    { Value: ReportPresentationOrder.ORDER_PACCHETTO_ALFABETICO, Text: "Pacchetto" },
    { Value: ReportPresentationOrder.ORDER_OPERATORE, Text: "Operatore" },

  ]
  ShareholderOrderBy: ReportPresentationOrder = ReportPresentationOrder.ORDER_ALFABETICO;
  ReportOutputOptions: any[] = [
    { Value: 0, Text: 'Foglio Excel (XLSX)' },
    { Value: 1, Text: 'Adobe PDF' },
    { Value: 2, Text: 'Tracciato PHOENIX su base Protocollo/CSID' },
    { Value: 3, Text: 'Tracciato PHOENIX su base CAG/CDG' },
    { Value: 4, Text: 'GEAS to GTA (Computershare)' }
  ];
  ReportOutput: number = 0;
  PrintDestinationOptions: any[] = [{ Value: PrintDestinationSelection.Anteprima, Text: "Anteprima" }, { Value: PrintDestinationSelection.Esportazione, Text: "Esportazione Dati" }];
  PrintDestination: PrintDestinationSelection = PrintDestinationSelection.Anteprima;

  PrintTypeOptions: any[] = [{ Value: PrintType.Azioni, Text: 'Azioni' }, { Value: PrintType.Numeri, Text: 'Numeri' }, { Value: PrintType.Completo, Text: 'Completo' }]
  PrintType: PrintType = PrintType.Completo;

  MeetingValidityOptions: any[] = [{ Value: MeetingValidity.Ordinary, Text: 'Ordinaria' }, { Value: MeetingValidity.Extra, Text: 'Straordinaria' }];
  MeetingValidity: MeetingValidity = MeetingValidity.Ordinary;

  get Anteprima(): boolean {
    return this.PrintDestination == PrintDestinationSelection.Anteprima;
  }

  PrintTimeSelectionOptions: any[] = [{ Value: PrintTimeSelection.CurrentTime, Text: "Ora Attuale" }, { Value: PrintTimeSelection.ManualSelection, Text: "Selezione Manuale" }, { Value: PrintTimeSelection.Event, Text: "Inizio Attività" }]

  ReportPresentiTypeOptions: any[] = [
    { Value: ReportPresentiType.Sintesi, Text: 'Sintesi' },
    { Value: ReportPresentiType.Dettaglio, Text: 'Dettaglio' },
    { Value: ReportPresentiType.Preregistrati, Text: 'Pre-Registrati' },
    { Value: ReportPresentiType.FormatoLegale, Text: 'Formato Legale' },
    { Value: ReportPresentiType.Deleghe, Text: 'Deleghe in riga (solo excel)' }
  ];
  ReportPresentiType: ReportPresentiType = ReportPresentiType.Sintesi;


  ReportCoopTypeOptions: any[] = [
    { Value: ReportCoopType.Votanti, Text: 'Votanti' },
    { Value: ReportCoopType.Voti, Text: 'Voti' } 
  ];
  ReportCoopType: ReportCoopType = ReportCoopType.Votanti;

  //devo usare una funzione per calcolare il datasource delle opzioni per il report dei partecipanti, perchè il radiogroup è buggato e un getter lo prende ma poi non fa cambiare il valore
  //le opzioni per questo datasource vengono ricalcolare quando vengono caricati i sysconfig per gestire la visibilità di alcune opzioni, e quando cambia il timing selezionato per disabilitare il report con voti
  GetReportPartecipantiTypeOptions(): any[] {
    return [
      { Value: ReportPartecipantiType.Partecipanti, Text: 'Partecipanti', visible: true },
      { Value: ReportPartecipantiType.PartecipantiLegale, Text: 'Partecipanti Legale', visible: true },
      { Value: ReportPartecipantiType.Registrazioni, Text: 'Registrazioni', visible: true },
      { Value: ReportPartecipantiType.PartecipantiVoti, Text: 'Partecipanti con presenze ai voti', visible: true, disabled: !this.PrintCurrentTime },
      { Value: ReportPartecipantiType.PartecipantiFisici, Text: 'Partecipanti fisici (soc. dolce, solo pdf)', visible: !this.configs.HideOvercustomizedReports },
      { Value: ReportPartecipantiType.PartecipantiSoci, Text: 'Partecipanti con soci rappresentati (soc. dolce, solo pdf)', visible: !this.configs.HideOvercustomizedReports },
    ]
  };
  ReportPartecipantiTypeOptions: any[] = [];
  ReportPartecipantiType: ReportPartecipantiType = ReportPartecipantiType.Partecipanti;

  MostraSoloSociValutatiNellePresenze: boolean = true;
  DistintaPerZona: boolean = false;
  private _PrintTimeSelection: PrintTimeSelection = PrintTimeSelection.CurrentTime;
  get PrintTimeSelection(): PrintTimeSelection { return this._PrintTimeSelection; }
  set PrintTimeSelection(value) {
    this._PrintTimeSelection = value;
    if (!this.PrintCurrentTime && this.ReportPartecipantiType == ReportPartecipantiType.PartecipantiVoti) {
      this.ReportPartecipantiType = ReportPartecipantiType.Partecipanti;
    }
    this.ReportPartecipantiTypeOptions = this.GetReportPartecipantiTypeOptions();
  }

  get PrintCurrentTime(): boolean {
    return this._PrintTimeSelection == PrintTimeSelection.CurrentTime;
  }
  set PrintCurrentTime(value) {
    if (value == true) {
      this._PrintTimeSelection = PrintTimeSelection.CurrentTime;
    }

  }
  get PrintSelectedTime(): boolean { return this._PrintTimeSelection == PrintTimeSelection.ManualSelection; }
  set PrintSelectedTime(value) {
    if (value == true) {
      this._PrintTimeSelection = PrintTimeSelection.ManualSelection;
    }
  }

  get PrintEventTime(): boolean { return this._PrintTimeSelection == PrintTimeSelection.Event; }
  set PrintEventTime(value) {
    if (value == true) {
      this._PrintTimeSelection = PrintTimeSelection.Event;
    }
  }

  private _ManualQueryTime: Date = new Date(Date.now());

  get ManualQueryTime(): Date { return this._ManualQueryTime; }
  set ManualQueryTime(value) {
    if (this._ManualQueryTime != value) {
      this._ManualQueryTime = value;
      if (this._PrintTimeSelection == PrintTimeSelection.ManualSelection) {
        this.FinalQueryTime = this.ManualQueryTime;
      }
    }
  }

  private _FinalQueryTime: Date = new Date(Date.now());

  get FinalQueryTime(): Date {
    return this._FinalQueryTime;
  }
  set FinalQueryTime(value) {
    if (this._FinalQueryTime != value) {
      this._FinalQueryTime = value;
    }
  }

  //#region Checkbox seleziona tutti
  private _AllSintesiPresenti: boolean = false;
  get AllSintesiPresenti(): boolean { return this._AllSintesiPresenti; };
  set AllSintesiPresenti(value: boolean) {
    this._AllSintesiPresenti = value;
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0 && !isNullOrUndefined(value)) {
      this.AssembleaEvents.forEach(evento => {
        if (this.PrintType == PrintType.Completo) {
          evento.PrintSintesiPresentsReport = value;
        }
        if (this.PrintType == PrintType.Numeri) {
          evento.PrintSintesiPresentsNumReport = value;
        }
        if (this.PrintType == PrintType.Azioni) {
          evento.PrintSintesiPresentsAzReport = value;
        }
      });
    }
  }
  private _AllDettaglioPresenti: boolean = false;
  get AllDettaglioPresenti(): boolean { return this._AllDettaglioPresenti; };
  set AllDettaglioPresenti(value: boolean) {
    this._AllDettaglioPresenti = value;
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0 && !isNullOrUndefined(value)) {
      this.AssembleaEvents.forEach(evento => {
        evento.PrintDettaglioPresentsReport = value;
      });
    }
  }
  private _AllPartecipanti: boolean = false;
  get AllPartecipanti(): boolean { return this._AllPartecipanti; }
  set AllPartecipanti(value: boolean) {
    this._AllPartecipanti = value;
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0 && !isNullOrUndefined(value)) {
      this.AssembleaEvents.forEach(evento => {
        evento.PrintParticipantsReport = value;
      });
    }
  }
  private _AllSintesiVoti: boolean = false;
  get AllSintesiVoti(): boolean { return this._AllSintesiVoti; };
  set AllSintesiVoti(value: boolean) {
    this._AllSintesiVoti = value;
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0 && !isNullOrUndefined(value)) {
      this.AssembleaEvents.forEach(evento => {
        if (this.PrintType == PrintType.Completo) {
          evento.PrintSintesiVotesReport = value;
        }
        if (this.PrintType == PrintType.Numeri) {
          evento.PrintSintesiVotesNumReport = value;
        }
        if (this.PrintType == PrintType.Azioni) {
          evento.PrintSintesiVotesAzReport = value;
        }
      });
    }
  }
  private _AllDettaglioVoti: boolean = false;
  get AllDettaglioVoti(): boolean { return this._AllDettaglioVoti; }
  set AllDettaglioVoti(value: boolean) {
    this._AllDettaglioVoti = value;
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0 && !isNullOrUndefined(value)) {
      this.AssembleaEvents.forEach(evento => {
        if (this.PrintType == PrintType.Completo) {
          evento.PrintDettaglioVotesReport = value;
        }
        if (this.PrintType == PrintType.Numeri) {
          evento.PrintDettaglioVotesNumReport = value;
        }
        if (this.PrintType == PrintType.Azioni) {
          evento.PrintDettaglioVotesAzReport = value;
        }
      });
    }
  }
  private _AllSchedeDiVoto: boolean = false;
  get AllSchedeDiVoto(): boolean { return this._AllSchedeDiVoto; }
  set AllSchedeDiVoto(value: boolean) {
    this._AllSchedeDiVoto = value;
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0 && !isNullOrUndefined(value)) {
      this.AssembleaEvents.filter(v => v.HasVoteCardReport).forEach(evento => {
        evento.PrintVoteCardsReport = value;
      });
    }
  }
  //#endregion
  //#region Single events check
  eSintesiPresenti_ValueChanged(e) {
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0) {
      let count = this.AssembleaEvents.filter(v => v.PrintSintesiPresentsReport == false).length;
      if (count == this.AssembleaEvents.length) {
        this._AllSintesiPresenti = false;
      }
      else if (count < this.AssembleaEvents.length && count > 0) {
        this._AllSintesiPresenti = undefined;
      }
      else {
        this._AllSintesiPresenti = true;
      }
    }
  }
  eDettaglioPresenti_ValueChanged(e) {
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0) {
      let count = this.AssembleaEvents.filter(v => v.PrintDettaglioPresentsReport == false).length;
      if (count == this.AssembleaEvents.length) {
        this._AllDettaglioPresenti = false;
      }
      else if (count < this.AssembleaEvents.length && count > 0) {
        this._AllDettaglioPresenti = undefined;
      }
      else {
        this._AllDettaglioPresenti = true;
      }
    }
  }
  ePartecipanti_ValueChanged(e) {
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0) {
      let count = this.AssembleaEvents.filter(v => v.PrintParticipantsReport == false).length;
      if (count == this.AssembleaEvents.length) {
        this._AllPartecipanti = false;
      }
      else if (count < this.AssembleaEvents.length && count > 0) {
        this._AllPartecipanti = undefined;
      }
      else {
        this._AllPartecipanti = true;
      }
    }
  }
  eSintesiVoti_ValueChanged(e) {
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0) {
      let count = this.AssembleaEvents.filter(v => v.HasVoteReport && v.PrintSintesiVotesReport == false).length;
      if (count == this.AssembleaEvents.filter(v => v.HasVoteReport).length) {
        this._AllSintesiVoti = false;
      }
      else if (count < this.AssembleaEvents.filter(v => v.HasVoteReport).length && count > 0) {
        this._AllSintesiVoti = undefined;
      }
      else {
        this._AllSintesiVoti = true;
      }
    }
  }
  eDettaglioVoti_ValueChanged(e) {
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0) {
      let count = this.AssembleaEvents.filter(v => v.HasVoteReport && v.PrintDettaglioVotesReport == false).length;
      if (count == this.AssembleaEvents.filter(v => v.HasVoteReport).length) {
        this._AllDettaglioVoti = false;
      }
      else if (count < this.AssembleaEvents.filter(v => v.HasVoteReport).length && count > 0) {
        this._AllDettaglioVoti = undefined;
      }
      else {
        this._AllDettaglioVoti = true;
      }
    }
  }
  eSchedeVoto_ValueChanged(e) {
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0) {
      let count = this.AssembleaEvents.filter(v => v.HasVoteCardReport && v.PrintVoteCardsReport == false).length;
      if (count == this.AssembleaEvents.filter(v => v.HasVoteCardReport).length) {
        this._AllSchedeDiVoto = false;
      }
      else if (count < this.AssembleaEvents.filter(v => v.HasVoteCardReport).length && count > 0) {
        this._AllSchedeDiVoto = undefined;
      }
      else {
        this._AllSchedeDiVoto = true;
      }
    }
  }

  //#endregion
  AssembleaEvents: vEventoAssemblea[] = [];
  private _ReportRequest: ReportRequest = null;
  private timer: any = null;

  //#region Report Movimenti
  StampaIngressi: boolean = true;
  StampaUscite: boolean = true;
  MovementsFormEvent: vEventoAssemblea = null;
  MovementsToEvent: vEventoAssemblea = null;
  MovementCSID: string = "";
  //#endregion
  //region Report elenco soci
  MostraTotaleSoci: boolean = false;
  MostraTotaleAzioni: boolean = false;
  //#endregion
  //#region Report Ospiti
  ReportOspitiTypeOptions: any[] = [
    { Value: ReportOspitiType.Presenti, Text: 'Presenti' },
    { Value: ReportOspitiType.Attesi, Text: 'Attesi' }
  ];
  ReportOspitiType: ReportOspitiType = ReportOspitiType.Presenti;
  //#endregion
  //#region configurazione stampe evento
  PrintSintesiEvento: boolean = true;
  PrintDettaglioEvento: boolean = false;
  PrintFormatoLegaleEvento: boolean = false;
  PrintParticipantsEvento: boolean = false;
  PrintLegalParticipantsEvento: boolean = false;
  StampaAventiDiritto: boolean = false;
  StampaRisultati: boolean = false;

  private _ShowVoteDetail: boolean = false;
  get ShowVoteDetail(): boolean { return this._ShowVoteDetail && this.StampaRisultati };
  set ShowVoteDetail(value: boolean) { this._ShowVoteDetail = value; };

  private _IncludeNonVoters: boolean = false;
  get IncludeNonVoters(): boolean { return this._IncludeNonVoters && this.StampaRisultati };
  set IncludeNonVoters(value: boolean) { this._IncludeNonVoters = value; };

  private _OperatorDetails: boolean = false;
  get OperatorDetails(): boolean { return this._OperatorDetails && this.StampaRisultati };
  set OperatorDetails(value: boolean) { this._OperatorDetails = value; };

  VoteExcludePreferenceOptions: string[] = ["", "Favorevoli", "Contrari", "Astenuti"];
  VoteExcludePreference: string = 'Favorevoli';

  get StampaSchedeConsegnateEnabled(): boolean {
    if (!isNullOrUndefined(this.AssembleaVoteCards) && !isNullOrUndefined(this.SelectedVoteCards.find(v => v.ManagedDelivery))) {
      return true;
    }

    return false;
  }


  get StampaSchedeRitirateEnabled(): boolean {

    if (!isNullOrUndefined(this.AssembleaVoteCards) && !isNullOrUndefined(this.SelectedVoteCards.find(v => v.ManagedCollecting))) {
      return true;
    }

    return false;

  }

  StampaSchedeConsegnate: boolean = false;
  StampaSchedeRitirate: boolean = false;

  //#endregion
  get SelectedEvent(): vEventoAssemblea {
    if (this.SelectedEventItems.length > 0)
      return this.SelectedEventItems[0];
  }
  set SelectedEvent(value: vEventoAssemblea) {
    this.SelectedEventItems = [value];
    this.UpdateUIConsegnaSchede();
  }
  private _SelectedEventItems: vEventoAssemblea[] = [];
  get SelectedEventItems(): vEventoAssemblea[] { return this._SelectedEventItems; };
  set SelectedEventItems(value: vEventoAssemblea[]) {
    this._SelectedEventItems = value;
    this.UpdateUIConsegnaSchede();
  }
  /** PrintPage ctor */
  constructor(private cd: ChangeDetectorRef, private storage: StorageService, private assembleaService: AssembleaService, private sysConfigService: SysConfigService, private printService: PrintingService, private logService: LogService, private dialog: MatDialog) {
    this.InitTimer();
    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
      this.ReportPartecipantiTypeOptions = this.GetReportPartecipantiTypeOptions();
    });
    this.subscriptions.add(sysConfigSub);
    this.LoadAssembleaEvents().then(() => {
      this.LoadEventVoteCards().then(() => {
        this.UpdateUIConsegnaSchede();
        this.LoadPrintPageConfiguration();
        this.cd.detectChanges();
      });
    });
  }
  ngOnDestroy(): void {
    this.DisposeTimer();
    this.subscriptions.unsubscribe();
  }
  private loadConfig() {
    this.configs.MeetingGeneralType = this.sysConfigService.GetSysConfigValue(SysConfigItems.MeetingGeneralType, MeetingGeneralType.Generic);
    this.configs.HideOvercustomizedReports = this.sysConfigService.GetSysConfigValue(SysConfigItems.HideOvercustomizedReports, true);
    this.configs.CoopAlleanza = this.sysConfigService.GetSysConfigValue(SysConfigItems.CoopAlleanza30, false);
    this.configs.DenyExitWithPrevote = this.sysConfigService.GetSysConfigValue(SysConfigItems.DenyExitWithPrevote, false);
  }

  private UpdateImpostazioni() {
    switch (this._PrintTimeSelection) {
      case PrintTimeSelection.ManualSelection:
        this.FinalQueryTime = this.ManualQueryTime;
        break;
      case PrintTimeSelection.Event:
        break;
      case PrintTimeSelection.CurrentTime:
      default:
        this.FinalQueryTime = new Date(Date.now());
        break;
    }
  }
  //#region Presenter
  async LoadAssembleaEvents() {
    try {
      this.AssembleaEvents = vEventoAssemblea.ToListOfInstance(await this.assembleaService.getEvents4ReportPresences());
      if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0) {
        this.SelectedEventItems = [this.AssembleaEvents[0]];
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nel caricamento degli eventi",
            message: error.Detail.detail.UIMessage,
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }

  }
  async LoadEventVoteCards() {
    try {
      this.AssembleaVoteCards = await this.assembleaService.loadVoteCards(true);

      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nel caricamento delle schede",
            message: error.Detail.detail.UIMessage,
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }

  }
  private UpdateUIConsegnaSchede() {
    if (!isNullOrUndefined(this.AssembleaEvents) && !isNullOrUndefined(this.AssembleaVoteCards)) {

      if (this.SelectedEvent != null) {
        let tmpSelected: VoteCard[] = [];

        let vc: VoteCard = this.AssembleaVoteCards.find(v => v.VCID == this.SelectedEvent.VCID);
        if (!isNullOrUndefined(vc)) {
          tmpSelected.push(vc);
        }

        this.SelectedVoteCards = tmpSelected;
      }
    }
  }
  //#endregion
  PrintTimeSelectionChanged(e) {
    this.UpdateImpostazioni();
  }
  //#region Timer
  private InitTimer() {
    if (this.timer != null) {
      this.DisposeTimer();
    }
    this.timer_Tick = this.timer_Tick.bind(this);
    this.timer = window.setInterval(this.timer_Tick, 500);
  }

  private DisposeTimer() {
    if (this.timer != null) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
  }

  private timer_Tick() {
    //CurrentTimeString = DateTime.Now.ToString("F");

    if (this.PrintCurrentTime) {
      this.FinalQueryTime = new Date(Date.now());
      //this.cd.detectChanges();
    }
  }
  //#endregion
  private AssembleaReportsPrepareCommonResponse() {
    this._ReportRequest = new ReportRequest();
    let parameters: ReportParameters = new ReportParameters();
    this._ReportRequest.Parameters = parameters;
    parameters.CustomProperties = new Map();

    if (this.Anteprima) {
      parameters.IsPreview = true;
      parameters.IsExport = false;
      parameters.PrintOnServer = false;
      parameters.PrinterName = "";
    }
    else {
      parameters.IsPreview = false;
      parameters.IsExport = true;
      parameters.PrintOnServer = false;
      parameters.PrinterName = "";

      switch (this.ReportOutput) {
        case 0:
          parameters.ReportOutput = ReportOuput.XLSX;
          break;
        case 1:
          parameters.ReportOutput = ReportOuput.PDF;
          break;
        case 2:
          parameters.ReportOutput = ReportOuput.PHOENIX;
          parameters.CustomProperties.set(ReportCustomProperties.PhoenixExporter_UseCDG, false);
          break;
        case 3:
          parameters.ReportOutput = ReportOuput.PHOENIX;
          parameters.CustomProperties.set(ReportCustomProperties.PhoenixExporter_UseCDG, true);
          break;
        case 4:
          parameters.ReportOutput = ReportOuput.COMPUTERSHARE;
          break;
        default:
          parameters.ReportOutput = ReportOuput.PDF;
          break;
      }
    }

    parameters.Copies = 1;

    this._ReportRequest.Parameters.QueryTimeIsNow = this.PrintCurrentTime;
    this._ReportRequest.Parameters.QueryTime = this.FinalQueryTime;

    this._ReportRequest.Parameters.EventDescr = "";

    if (this.MeetingValidity == MeetingValidity.Ordinary) {
      //ORDINARIA
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.TipologiaAssemblea, MeetingValidity.Ordinary);
    }
    else {
      //STRAORDINARIA
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.TipologiaAssemblea, MeetingValidity.Extra);
    }

    this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.AssembleaEventType, this.configs.MeetingGeneralType);
  }

  async HandleCoopPrint(e) {
    e.preventDefault();
    try {
      let reportType: ReportTypes;

      this.AssembleaReportsPrepareCommonResponse();
      
      this._ReportRequest.Parameters.IsPreview = false;
      this._ReportRequest.Parameters.IsExport = true;
      this._ReportRequest.Parameters.ReportOutput = ReportOuput.XLSX

      switch (this.ReportCoopType) {
        case ReportCoopType.Votanti:
          reportType = ReportTypes.CoopVotanti;
          break;
        case ReportCoopType.Voti:
          reportType = ReportTypes.CoopVoti;
          break;
        default:
          reportType = ReportTypes.CoopVotanti;
          break;
      }

      this._ReportRequest.ReportType = reportType;

      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);

      //ShowInStatistics
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ViewOnlyPAWithShowInStatiscticsFlagActive, false);
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.DistintaPerZona, false);

      await this.printService.SendPrintRequest(this._ReportRequest);
    }
    catch (exception) {
      console.error(exception);
      this.logService.EnqueueLog(exception);
      //Logger.Error(exception.Message, exception);
    }
  }

  async HandlePresentsPrint(e) {
    e.preventDefault();
    try {
      let reportType: ReportTypes;

      this.AssembleaReportsPrepareCommonResponse();

      switch (this.ReportPresentiType) {
        case ReportPresentiType.Dettaglio:
          reportType = ReportTypes.ShareholderPresentsDetail;
          break;
        case ReportPresentiType.FormatoLegale:
          reportType = ReportTypes.ShareholderPresentsLegal;
          break;
        case ReportPresentiType.Preregistrati:
          reportType = ReportTypes.ShareholderPresentsPreAccounting;
          break;
        case ReportPresentiType.Deleghe:
          reportType = ReportTypes.ShareholderPresentsWithDelegationInRow; // alba
          break;
        case ReportPresentiType.Sintesi:
          if (this.PrintType == PrintType.Numeri) {
            reportType = ReportTypes.ShareholderPresentsNum;
          }
          else if (this.PrintType == PrintType.Azioni) {
            reportType = ReportTypes.ShareholderPresentsAz;
          }
          else {
            reportType = ReportTypes.ShareholderPresents;
          }
          break;
        default:
          reportType = ReportTypes.ShareholderPresents;
          break;
      }

      this._ReportRequest.ReportType = reportType;

      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);

      //ShowInStatistics
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ViewOnlyPAWithShowInStatiscticsFlagActive, this.MostraSoloSociValutatiNellePresenze);
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.DistintaPerZona, this.DistintaPerZona);

      await this.printService.SendPrintRequest(this._ReportRequest);
    }
    catch (exception) {
      console.error(exception);
      this.logService.EnqueueLog(exception);
      //Logger.Error(exception.Message, exception);
    }
  }
  async HandleParticipantsPrint(e) {
    e.preventDefault();
    try {
      this.AssembleaReportsPrepareCommonResponse();

      switch (this.ReportPartecipantiType) {
        case ReportPartecipantiType.PartecipantiVoti:
          this._ReportRequest.ReportType = ReportTypes.ParticipantsWithPolls;
          break;
        case ReportPartecipantiType.PartecipantiLegale:
          this._ReportRequest.ReportType = ReportTypes.ParticipantsLegal;
          break;
        case ReportPartecipantiType.Registrazioni:
          this._ReportRequest.ReportType = ReportTypes.Accounting;
          break;
        case ReportPartecipantiType.PartecipantiFisici:
          this._ReportRequest.ReportType = ReportTypes.ReportPartecipantiFisici;
          break;
        case ReportPartecipantiType.PartecipantiSoci:
          this._ReportRequest.ReportType = ReportTypes.ReportPartecipantiAlbero;
          break;
        case ReportPartecipantiType.Partecipanti:
        default:
          this._ReportRequest.ReportType = ReportTypes.Participants;
          break;
      }

      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);
      await this.SendPrintRequest(this._ReportRequest);
    }
    catch (exception) {
      console.error(exception);
      this.logService.EnqueueLog(exception);
    }
  }
  async HandleMovementsPrint(e) {
    e.preventDefault();
    try {
      this.AssembleaReportsPrepareCommonResponse();

      this._ReportRequest.ReportType = ReportTypes.Movements;

      // TODO: REVISIONE EVENTI REGIA
      if (this.MovementsFormEvent != null) {
        let evaluationTime: Date = null;
        let ea: vEventoAssemblea = this.MovementsFormEvent;
        if (ea.EvaluationType == PollInterval.Chiusura) {
          evaluationTime = isNullOrUndefined(ea.TimestampChiusura) ? null : ea.TimestampChiusura;
        }
        else {
          evaluationTime = isNullOrUndefined(ea.TimestampApertura) ? null : ea.TimestampApertura;
        }

        if (evaluationTime == null) {
          return;
        }

        this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.MovementFrom, evaluationTime);
        this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.MovementFromEventDescr, ea.Descr);
      }

      if (this.MovementsToEvent != null) {
        let evaluationTime: Date = null;
        let ea: vEventoAssemblea = this.MovementsToEvent;
        if (ea.EvaluationType == PollInterval.Chiusura) {
          evaluationTime = isNullOrUndefined(ea.TimestampChiusura) ? null : ea.TimestampChiusura;
        }
        else {
          evaluationTime = isNullOrUndefined(ea.TimestampApertura) ? null : ea.TimestampApertura;
        }

        if (evaluationTime == null) {
          return;
        }

        this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.MovementTo, evaluationTime);
        this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.MovementToEventDescr, ea.Descr);
      }

      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.MovementOfShareholder, this.MovementCSID);
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.MovementIn, this.StampaIngressi);
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.MovementOut, this.StampaUscite);
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ShowStructureParentChild, true);
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);

      await this.SendPrintRequest(this._ReportRequest);
    }
    catch (exception) {
      console.error(exception);
      this.logService.EnqueueLog(exception);
    }
  }
  async HandleShareholdersPrint(e) {
    e.preventDefault();
    try {
      this.AssembleaReportsPrepareCommonResponse();
      this._ReportRequest.Parameters.QueryTimeIsNow = true;
      this._ReportRequest.ReportType = ReportTypes.Shareholders;
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ShowShareholderTotalNum, this.MostraTotaleSoci);
      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ShowShareholderTotalNumOfShare, this.MostraTotaleAzioni);

      await this.SendPrintRequest(this._ReportRequest);
    }
    catch (exception) {
      console.error(exception);
      this.logService.EnqueueLog(exception);
    }
  }
  async HandleGuestPrint(e) {
    e.preventDefault();
    try {
      this.AssembleaReportsPrepareCommonResponse();

      switch (this.ReportOspitiType) {
        case ReportOspitiType.Attesi:
          this._ReportRequest.ReportType = ReportTypes.GuestParticipants;
          break;
        case ReportOspitiType.Presenti:
        default:
          this._ReportRequest.ReportType = ReportTypes.GuestInside;
          break;
      }

      this._ReportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);

      await this.SendPrintRequest(this._ReportRequest);
    }
    catch (exception) {
      console.error(exception);
      this.logService.EnqueueLog(exception);
    }
  }
  async HandleSpeechPrint(e) {
    e.preventDefault();
    try {
      this.AssembleaReportsPrepareCommonResponse();
      this._ReportRequest.ReportType = ReportTypes.SpeechAccounting;
      await this.SendPrintRequest(this._ReportRequest);
    }
    catch (exception) {
      console.error(exception);
      this.logService.EnqueueLog(exception);
    }
  }
  async HandleLotteryPrint(e) {
    e.preventDefault();
    try {
      this.AssembleaReportsPrepareCommonResponse();
      this._ReportRequest.ReportType = ReportTypes.Lottery;
      await this.SendPrintRequest(this._ReportRequest);
    }
    catch (exception) {
      console.error(exception);
      this.logService.EnqueueLog(exception);
    }
  }
  private async SendPrintRequest(reportRequest: ReportRequest) {
    await this.printService.SendPrintRequest(reportRequest);
  }
  //#region print check configuration

  private _PrintPageConfiguration: PrintPageConfiguration;

  DeletePrintConfiguration_Click(e) {
    try {
      this.storage.delete("PrintPageConfiguration", false);
      this._PrintPageConfiguration = new PrintPageConfiguration();

      if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0) {
        this.AssembleaEvents.forEach(evento => {
          evento.PrintSintesiPresentsReport = false;
          evento.PrintSintesiPresentsAzReport = false;
          evento.PrintSintesiPresentsNumReport = false;
          evento.PrintDettaglioPresentsReport = false;
          evento.PrintParticipantsReport = false;
          evento.PrintSintesiVotesReport = false;
          evento.PrintSintesiVotesNumReport = false;
          evento.PrintSintesiVotesAzReport = false;
          evento.PrintDettaglioVotesReport = false;
          evento.PrintDettaglioVotesNumReport = false;
          evento.PrintDettaglioVotesAzReport = false;
          evento.PrintVoteCardsReport = false;
        });
      }

      this.AllSintesiPresenti = false;
      this.AllDettaglioPresenti = false;
      this.AllPartecipanti = false;
      this.AllSintesiVoti = false;
      this.AllDettaglioVoti = false;
      this.AllSchedeDiVoto = false;

      this.PrintSintesiEvento = false;
      this.PrintDettaglioEvento = false;
      this.PrintFormatoLegaleEvento = false;
      this.MostraSoloSociValutatiNellePresenze = true;
      this.DistintaPerZona = false;
      this.PrintParticipantsEvento = false;
      this.PrintLegalParticipantsEvento = false;
      this.StampaAventiDiritto = false;
      this.StampaRisultati = false;
      this.ShowVoteDetail = false;
      this.IncludeNonVoters = false;
      this.OperatorDetails = false;
      this.VoteExcludePreference = this.VoteExcludePreferenceOptions[0];
      this.StampaSchedeConsegnate = false;
      this.StampaSchedeRitirate = false;
    }
    catch (e) {
      console.error(e);
      //Logger.Error("Error clearing configuration", ex);
    }
  }

  private LoadPrintPageConfiguration() {
    try {
      this._PrintPageConfiguration = this.storage.retrieve("PrintPageConfiguration", false);

      if (!isNullOrUndefined(this._PrintPageConfiguration)) {
        this.ApplyPrintPageConfiguration();
      }
    }
    catch (e) {
      console.error(e);
      //Logger.Error("Error recovering print page configuration", ex);
    }
  }

  private SavePrintPageConfiguration() {
    try {
      if (isNullOrUndefined(this._PrintPageConfiguration)) {
        this._PrintPageConfiguration = new PrintPageConfiguration();
      }

      this.StorePrintPageConfiguration();
      this.storage.store("PrintPageConfiguration", this._PrintPageConfiguration, false);

    }
    catch (e) {
      console.error(e);
      //Logger.Error("Error saving print page configuration", ex);
    }
  }

  private ApplyPrintPageConfiguration() {
    if (isNullOrUndefined(this._PrintPageConfiguration)) {
      // does not exists
      return;
    }

    if (!isNullOrUndefined(this.AssembleaEvents) && this._PrintPageConfiguration.ConfigurazioneEventi != null && this._PrintPageConfiguration.ConfigurazioneEventi.length > 0) {
      this._PrintPageConfiguration.ConfigurazioneEventi.forEach(config => {
        let evento: vEventoAssemblea = this.AssembleaEvents.find(v => v.EAID == config.EAID);
        if (!isNullOrUndefined(evento)) {
          this.ApplyEventConfiguration(config, evento);
        }
      });
    }
    this.PrintSintesiEvento = this._PrintPageConfiguration.PresentiSintesi;
    this.PrintDettaglioEvento = this._PrintPageConfiguration.PresentiDettaglio;
    this.PrintFormatoLegaleEvento = this._PrintPageConfiguration.PresentiLegale;
    this.PrintParticipantsEvento = this._PrintPageConfiguration.Partecipanti;
    this.PrintLegalParticipantsEvento = this._PrintPageConfiguration.PartecipantiLegale;
    this.StampaAventiDiritto = this._PrintPageConfiguration.VotazioniAventiDiritto;
    this.StampaRisultati = this._PrintPageConfiguration.VotazioniRisultati;
    this.ShowVoteDetail = this._PrintPageConfiguration.VotazioniDettaglio;
    this.IncludeNonVoters = this._PrintPageConfiguration.VotazioniIncludiNonVotanti;
    this.OperatorDetails = this._PrintPageConfiguration.VotazioniDettaglioOperatori;
    this.VoteExcludePreference = this._PrintPageConfiguration.VotazioniEsclusione;
    this.StampaSchedeConsegnate = this._PrintPageConfiguration.SchedeConsegnate;
    this.StampaSchedeRitirate = this._PrintPageConfiguration.SchedeRitirate;
  }

  private ApplyEventConfiguration(config: PrintEventConfiguration, evento: vEventoAssemblea) {
    if (!isNullOrUndefined(config) && !isNullOrUndefined(evento)) {
      evento.PrintSintesiPresentsReport = config.PrintSintesiPresenti;
      evento.PrintSintesiPresentsAzReport = config.PrintSintesiPresentiAz;
      evento.PrintSintesiPresentsNumReport = config.PrintSintesiPresentiNum;
      evento.PrintDettaglioPresentsReport = config.PrintDettaglioPresenti;
      evento.PrintParticipantsReport = config.PrintPartecipanti;
      evento.PrintSintesiVotesReport = config.PrintSintesiVoti;
      evento.PrintSintesiVotesAzReport = config.PrintSintesiVotiAz;
      evento.PrintSintesiVotesNumReport = config.PrintSintesiVotiNum;
      evento.PrintDettaglioVotesReport = config.PrintDettaglioVoti;
      evento.PrintDettaglioVotesNumReport = config.PrintDettaglioVotiNum;
      evento.PrintDettaglioVotesAzReport = config.PrintDettaglioVotiAz;
      evento.PrintVoteCardsReport = config.PrintSchedeVoto;
    }
  }

  private StorePrintPageConfiguration() {
    if (isNullOrUndefined(this._PrintPageConfiguration)) {
      this._PrintPageConfiguration = new PrintPageConfiguration();
    }
    if (isNullOrUndefined(this._PrintPageConfiguration.ConfigurazioneEventi)) {
      this._PrintPageConfiguration.ConfigurazioneEventi = [];
    }

    this._PrintPageConfiguration.ConfigurazioneEventi = [];
    if (!isNullOrUndefined(this.AssembleaEvents) && this.AssembleaEvents.length > 0) {
      this.AssembleaEvents.forEach(evento => {
        let ec: PrintEventConfiguration = this.StoreEventConfiguration(evento);
        if (ec != null) {
          this._PrintPageConfiguration.ConfigurazioneEventi.push(ec);
        }
      });
    }

    this._PrintPageConfiguration.PresentiSintesi = isnull(this.PrintDettaglioEvento, false);
    this._PrintPageConfiguration.PresentiDettaglio = isnull(this.PrintDettaglioEvento, false);
    this._PrintPageConfiguration.PresentiLegale = isnull(this.PrintFormatoLegaleEvento, false);
    this._PrintPageConfiguration.Partecipanti = isnull(this.PrintParticipantsEvento, false);
    this._PrintPageConfiguration.PartecipantiLegale = isnull(this.PrintLegalParticipantsEvento, false);
    this._PrintPageConfiguration.VotazioniAventiDiritto = isnull(this.StampaAventiDiritto, false);
    this._PrintPageConfiguration.VotazioniRisultati = isnull(this.StampaRisultati, false);
    this._PrintPageConfiguration.VotazioniDettaglio = isnull(this.ShowVoteDetail, false);
    this._PrintPageConfiguration.VotazioniIncludiNonVotanti = isnull(this.IncludeNonVoters, false);
    this._PrintPageConfiguration.VotazioniDettaglioOperatori = isnull(this.OperatorDetails, false);
    this._PrintPageConfiguration.VotazioniEsclusione = isnull(this.VoteExcludePreference, "");
    this._PrintPageConfiguration.SchedeConsegnate = isnull(this.StampaSchedeConsegnate, false);
    this._PrintPageConfiguration.SchedeRitirate = isnull(this.StampaSchedeRitirate, false);
    this._PrintPageConfiguration.CreationDate = new Date(Date.now());
  }

  private StoreEventConfiguration(evento: vEventoAssemblea): PrintEventConfiguration {
    let config: PrintEventConfiguration = null;
    if (!isNullOrUndefined(evento)) {
      config = new PrintEventConfiguration(
        {
          EAID: evento.EAID,
          PrintSintesiPresenti: evento.PrintSintesiPresentsReport,
          PrintSintesiPresentiAz: evento.PrintSintesiPresentsAzReport,
          PrintSintesiPresentiNum: evento.PrintSintesiPresentsNumReport,
          PrintDettaglioPresenti: evento.PrintDettaglioPresentsReport,
          PrintPartecipanti: evento.PrintParticipantsReport,
          PrintSintesiVoti: evento.PrintSintesiVotesReport,
          PrintSintesiVotiAz: evento.PrintSintesiVotesAzReport,
          PrintSintesiVotiNum: evento.PrintSintesiVotesNumReport,
          PrintDettaglioVoti: evento.PrintDettaglioVotesReport,
          PrintDettaglioVotiAz: evento.PrintDettaglioVotesAzReport,
          PrintDettaglioVotiNum: evento.PrintDettaglioVotesNumReport,
          PrintSchedeVoto: evento.PrintVoteCardsReport
        });
    }

    return config;
  }

  //#endregion
  //#region Aggregato
  private async SaveReportTitles() {
    try {
      if (!isNullOrUndefined(this.AssembleaEvents)) {
        this.assembleaService.saveEventsReportTitles(this.AssembleaEvents);
      }

      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nel salvataggio dei titoli dei report",
            message: error.Detail.detail.UIMessage,
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }

  }
  private AssembleaEventoReportsPrepareCommonResponse(evento: vEventoAssemblea, preview: boolean): ReportRequest {
    let reportRequest: ReportRequest = new ReportRequest();
    let parameters: ReportParameters = new ReportParameters();
    reportRequest.Parameters = parameters;
    parameters.CustomProperties = new Map();

    if (preview) {
      if (this.Anteprima) {
        // configuro per ottenere la preview
        parameters.IsPreview = true;
        parameters.IsExport = false;
      }
      else {

        switch (reportRequest.ReportType) {
          case ReportTypes.ShareholderPresentsDetail:
          case ReportTypes.ShareholderPresentsLegal:
            switch (this.ReportOutput) {
              case 0:
                reportRequest.Parameters.ReportOutput = ReportOuput.XLSX;
                break;
              case 1:
                reportRequest.Parameters.ReportOutput = ReportOuput.PDF;
                break;
              case 2:
                reportRequest.Parameters.ReportOutput = ReportOuput.PHOENIX;
                reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.PhoenixExporter_UseCDG, false);
                break;
              case 3:
                reportRequest.Parameters.ReportOutput = ReportOuput.PHOENIX;
                reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.PhoenixExporter_UseCDG, true);
                break;
              case 4:
                reportRequest.Parameters.ReportOutput = ReportOuput.COMPUTERSHARE;
                break;
              default:
                reportRequest.Parameters.ReportOutput = ReportOuput.PDF;
                break;
            }
            break;
          case ReportTypes.ShareholderPresents:
          case ReportTypes.Participants:
          case ReportTypes.ParticipantsLegal:
          case ReportTypes.VoteCardDelivered:
          case ReportTypes.VoteCardCollected:
          case ReportTypes.ShareholderCanVotePoll:
          case ReportTypes.RealTimePolls:
          case ReportTypes.RealTimePollsNumber:
          case ReportTypes.RealTimePollsAction:
          case ReportTypes.ReportPartecipantiFisici:
          case ReportTypes.ReportPartecipantiAlbero:
          case ReportTypes.GuestInside:
          case ReportTypes.GuestParticipants:
            switch (this.ReportOutput) {
              case 0:
                parameters.IsPreview = false;
                parameters.IsExport = true;
                parameters.PrintOnServer = false;
                parameters.PrinterName = "";
                reportRequest.Parameters.ReportOutput = ReportOuput.XLSX;
                break;
              case 1:
                parameters.IsPreview = false;
                parameters.IsExport = true;
                parameters.PrintOnServer = false;
                parameters.PrinterName = "";
                reportRequest.Parameters.ReportOutput = ReportOuput.PDF;
                break;
              default:
                parameters.IsPreview = true;
                parameters.IsExport = false;
                break;
            }
            break;
        }
        /*
        switch (ComboPrintOutput.SelectedIndex)
        {
            case 0:
                // configuro per ottenere l'esportazione excel dei dati dell'evento
                parameters.IsPreview = false;
                parameters.IsExport = true;
                parameters.PrintOnServer = false;
                parameters.PrinterName = "";
                parameters.ReportOutput = EnumTypes.ReportOuput.XLSX;
                break;
            default:
                // configuro per ottenere la preview
                parameters.IsPreview = true;
                parameters.IsExport = false;
                break;
        }
        */
      }
    }
    else {
      // configuro per ottenere il report in pdf, sarà aggiornato
      // successivamente se necessario
      parameters.IsPreview = false;
      parameters.IsExport = true;
      parameters.ReportOutput = ReportOuput.PDF;
      // non è una preview, il report è ufficiale di default
      parameters.UsedAsNotarilDeed = true;
      switch (this.ReportOutput) {
        case 0:
          reportRequest.Parameters.ReportOutput = ReportOuput.XLSX;
          break;
        case 1:
          reportRequest.Parameters.ReportOutput = ReportOuput.PDF;
          break;
        case 2:
          reportRequest.Parameters.ReportOutput = ReportOuput.PHOENIX;
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.PhoenixExporter_UseCDG, false);
          break;
        case 3:
          reportRequest.Parameters.ReportOutput = ReportOuput.PHOENIX;
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.PhoenixExporter_UseCDG, true);
          break;
        case 4:
          reportRequest.Parameters.ReportOutput = ReportOuput.COMPUTERSHARE;
          break;
        default:
          reportRequest.Parameters.ReportOutput = ReportOuput.PDF;
          break;
      }
    }

    parameters.PrintOnServer = false;
    parameters.PrinterName = "";

    parameters.Copies = 1;

    reportRequest.Parameters.QueryTimeIsNow = false;

    if (evento.EventType == EventType.Evento) {
      reportRequest.Parameters.QueryTime = evento.TimestampApertura;
    }
    else {
      if (evento.EvaluationType == PollInterval.Chiusura) {
        reportRequest.Parameters.QueryTime = evento.TimestampChiusura;
      }
      else {
        reportRequest.Parameters.QueryTime = evento.TimestampApertura;
      }
    }

    reportRequest.Parameters.ODG = evento.ODG;
    reportRequest.Parameters.EventDescr = evento.Descr;

    if (evento.MeetingValidity == MeetingValidity.Extra) {
      //STRAORDINARIA
      reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.TipologiaAssemblea, MeetingValidity.Extra);
    }
    else {
      //ORDINARIA
      reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.TipologiaAssemblea, MeetingValidity.Ordinary);
    }

    reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.AssembleaEventType, this.MeetingValidity);
    reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.EAID, evento.EAID);

    return reportRequest;
  }
  private SetRequestOutputType(request: ReportRequest) {
    if (isNullOrUndefined(request) || isNullOrUndefined(request.Parameters)) {
      return;
    }

    if (this.Anteprima) {
      request.Parameters.ReportOutput = ReportOuput.PDF;
    }
    else {
      switch (request.ReportType) {
        case ReportTypes.ShareholderPresentsDetail:
        case ReportTypes.ShareholderPresentsLegal:
          switch (this.ReportOutput) {
            case 0:
              request.Parameters.ReportOutput = ReportOuput.XLSX;
              break;
            case 1:
              request.Parameters.ReportOutput = ReportOuput.PDF;
              break;
            case 2:
              request.Parameters.ReportOutput = ReportOuput.PHOENIX;
              request.Parameters.CustomProperties.set(ReportCustomProperties.PhoenixExporter_UseCDG, false);
              break;
            case 3:
              request.Parameters.ReportOutput = ReportOuput.PHOENIX;
              request.Parameters.CustomProperties.set(ReportCustomProperties.PhoenixExporter_UseCDG, true);
              break;
            case 4:
              request.Parameters.ReportOutput = ReportOuput.COMPUTERSHARE;
              break;
            default:
              request.Parameters.ReportOutput = ReportOuput.PDF;
              break;
          }
          break;
        case ReportTypes.ShareholderPresents:
        case ReportTypes.Participants:
        case ReportTypes.ParticipantsLegal:
        case ReportTypes.VoteCardDelivered:
        case ReportTypes.VoteCardCollected:
        case ReportTypes.ShareholderCanVotePoll:
        case ReportTypes.RealTimePolls:
        case ReportTypes.RealTimePollsNumber:
        case ReportTypes.RealTimePollsAction:
        case ReportTypes.ReportPartecipantiFisici:
        case ReportTypes.ReportPartecipantiAlbero:
        case ReportTypes.GuestInside:
        case ReportTypes.GuestParticipants:
          switch (this.ReportOutput) {
            case 0:
              request.Parameters.ReportOutput = ReportOuput.XLSX;
              break;
            case 1:
            default:
              request.Parameters.ReportOutput = ReportOuput.PDF;
              break;
          }
          break;
      }
    }
  }
  private PreparePresentiEventoRequest(reportType: ReportTypes, evento: vEventoAssemblea, preview: boolean = false): ReportRequest {
    let reportRequest: ReportRequest = null;
    try {
      // common
      reportRequest = this.AssembleaEventoReportsPrepareCommonResponse(evento, preview);

      reportRequest.ReportType = reportType;
      if (!preview) {
        this.SetRequestOutputType(reportRequest);
      }

      // presenti specific
      switch (reportType) {
        case ReportTypes.ShareholderPresents:
        case ReportTypes.ShareholderPresentsAz:
        case ReportTypes.ShareholderPresentsNum:
          reportRequest.Parameters.ReportTitle = evento.TitoloReportSintesiPresenti;
          break;
        case ReportTypes.ShareholderPresentsDetail:
        case ReportTypes.ShareholderPresentsLegal:
          reportRequest.Parameters.ReportTitle = evento.TitoloReportDettaglioPresenti;
          break;
      }

      reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);

      //ShowInStatistics
      reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ViewOnlyPAWithShowInStatiscticsFlagActive, this.MostraSoloSociValutatiNellePresenze);
      reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.DistintaPerZona, this.DistintaPerZona);
    }
    catch (e) {
      //Logger.Error(exception.Message, exception);
      console.error(e);
      reportRequest = null;
    }

    return reportRequest;
  }

  private PreparePartecipantiEventoRequest(reportType: ReportTypes, evento: vEventoAssemblea, preview: boolean = false): ReportRequest {
    let reportRequest: ReportRequest = null;
    try {
      // common
      reportRequest = this.AssembleaEventoReportsPrepareCommonResponse(evento, preview);

      reportRequest.ReportType = reportType;
      if (!preview) {
        this.SetRequestOutputType(reportRequest);
      }
      // partecipanti specific
      reportRequest.Parameters.ReportTitle = evento.TitoloReportPartecipanti;
      reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);
    }
    catch (e) {
      //Logger.Error(exception.Message, exception);
      console.error(e);
      reportRequest = null;
    }

    return reportRequest;
  }

  private PrepareVotazioniEventoRequest(reportType: ReportTypes, evento: vEventoAssemblea, showDetails: boolean = false, preview: boolean = false): ReportRequest {
    let reportRequest: ReportRequest = null;
    try {
      // common
      reportRequest = this.AssembleaEventoReportsPrepareCommonResponse(evento, preview);
      //Anteprima = preview;
      reportRequest.ReportType = reportType;
      if (!preview) {
        this.SetRequestOutputType(reportRequest);
      }
      // votazioni specific
      switch (reportType) {
        case ReportTypes.VoteCardDelivered:
        case ReportTypes.VoteCardCollected:
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.VoteCardID, evento.VCID);
          break;
        case ReportTypes.ShareholderCanVotePoll:
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.PollEventPEID, evento.PEID);
          break;
        case ReportTypes.RealTimePollsNumber:
          if (showDetails) {
            reportRequest.Parameters.ReportTitle = evento.TitoloReportDettaglioRisultatiVotazione;
          }
          else {
            reportRequest.Parameters.ReportTitle = evento.TitoloReportSintesiRisultatiVotazione;
          }
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ShowDetailData, showDetails);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ExcludePollDetailByName, this.VoteExcludePreference);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.IncludeNonVoters, this.IncludeNonVoters);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.OperatorDetails, this.OperatorDetails);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.PollEventPEID, evento.PEID);
          break;
        case ReportTypes.RealTimePollsAction:
          if (showDetails) {
            reportRequest.Parameters.ReportTitle = evento.TitoloReportDettaglioRisultatiVotazione;
          }
          else {
            reportRequest.Parameters.ReportTitle = evento.TitoloReportSintesiRisultatiVotazione;
          }
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ShowDetailData, showDetails);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ExcludePollDetailByName, this.VoteExcludePreference);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.IncludeNonVoters, this.IncludeNonVoters);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.OperatorDetails, this.OperatorDetails);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.PollEventPEID, evento.PEID);
          break;
        case ReportTypes.RealTimePolls:
        default:
          if (showDetails) {
            reportRequest.Parameters.ReportTitle = evento.TitoloReportDettaglioRisultatiVotazione;
          }
          else {
            reportRequest.Parameters.ReportTitle = evento.TitoloReportSintesiRisultatiVotazione;
          }
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ShowDetailData, showDetails);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ExcludePollDetailByName, this.VoteExcludePreference);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.IncludeNonVoters, this.IncludeNonVoters);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.OperatorDetails, this.OperatorDetails);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.Sorting, this.ShareholderOrderBy);
          reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.PollEventPEID, evento.PEID);
          break;
      }
    }
    catch (e) {
      //Logger.Error(exception.Message, exception);
      reportRequest = null;
      console.error(e);
    }

    return reportRequest;
  }

  async SaveReportTitles_Click(e) {
    e.preventDefault();
    this.SavePrintPageConfiguration();
    await this.SaveReportTitles();
    if (!this.InError) {
      notify("Titoli salvati", "success", 3000);
    }
  }
  async GenerateEventsReports_Click(e) {
    e.preventDefault();
    if (isNullOrUndefined(this.AssembleaEvents) || this.AssembleaEvents.length == 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Impossibile procedere",
        message: "Non sono presenti eventi di cui stampare i report.",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
      return;
    }

    this.SavePrintPageConfiguration();

    let printReport: boolean = false;
    this.AssembleaEvents.forEach(evento => {
      if (evento.PrintParticipantsReport ||
        evento.PrintSintesiPresentsReport ||
        evento.PrintSintesiPresentsAzReport ||
        evento.PrintSintesiPresentsNumReport ||
        evento.PrintDettaglioPresentsReport ||
        evento.PrintVoteCardsReport ||
        evento.PrintSintesiVotesReport ||
        evento.PrintSintesiVotesNumReport ||
        evento.PrintSintesiVotesAzReport ||
        evento.PrintDettaglioVotesReport ||
        evento.PrintDettaglioVotesNumReport ||
        evento.PrintDettaglioVotesAzReport) {
        printReport = true;
      }
    });

    if (!printReport) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Impossibile procedere",
        message: "Nessun evento selezionato per la stampa dei report!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
      return;
    }

    let summaryRequest: ReportRequest[] = [];

    let messageDettaglioPresenti: string = "";
    let messageDettaglioRisultatiVoto: string = "";
    let messageDettaglioPartecipanti: string = "";
    let messageVoteCard: string = "";

    let cantPrintVoteCardReport: boolean = false;
    let defaultDettaglioPresenti: boolean = false;
    let defaultPartecipanti: boolean = false;
    let defaultRisultatiVoto: boolean = false;
    // set list of report request
    await asyncForEach(this.AssembleaEvents, async (evento) => {
      if (evento.PrintSintesiPresentsReport && this.PrintType == PrintType.Completo) {
        // se è selezionato stampa
        let request: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresents, evento);
        if (request != null) {
          summaryRequest.push(request);
        }
      }
      if (evento.PrintSintesiPresentsReport && this.PrintType == PrintType.Azioni) {
        let request: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresentsAz, evento);
        if (request != null) {
          summaryRequest.push(request);
        }
      }
      if (evento.PrintSintesiPresentsReport && this.PrintType == PrintType.Numeri) {
        let request: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresentsNum, evento);
        if (request != null) {
          summaryRequest.push(request);
        }
      }
      if (evento.PrintDettaglioPresentsReport) {
        if (!this.PrintDettaglioEvento && !this.PrintFormatoLegaleEvento) {
          defaultDettaglioPresenti = true;
        }
        // se non è selezionato nessun tipo di dettaglio o è selezionato il dettaglio generico stampa
        if (this.PrintDettaglioEvento || (!this.PrintDettaglioEvento && !this.PrintFormatoLegaleEvento)) {
          let request: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresentsDetail, evento);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
        // stampa solo se è selezionato il dettaglio legale
        if (this.PrintFormatoLegaleEvento) {
          let request: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresentsLegal, evento);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
        // stampa solo se è selezionato il dettaglio fisico
        if (this.ReportPartecipantiType == ReportPartecipantiType.PartecipantiFisici) {
          let request: ReportRequest = this.PreparePartecipantiEventoRequest(ReportTypes.ReportPartecipantiFisici, evento, true);
          if (request != null) {
            await this.SendPrintRequest(request);
          }
        }
        // stampa solo se è selezionato il dettaglio albero
        if (this.ReportPartecipantiType == ReportPartecipantiType.PartecipantiSoci) {
          let request: ReportRequest = this.PreparePartecipantiEventoRequest(ReportTypes.ReportPartecipantiAlbero, evento, true);
          if (request != null) {
            await this.SendPrintRequest(request);
          }
        }
      }
      if (evento.PrintParticipantsReport) {
        if (!this.PrintParticipantsEvento && !this.PrintLegalParticipantsEvento) {
          defaultPartecipanti = true;
        }
        // se non è selezionato nessun tipo di report o è selezionato il report generico stampa
        if (this.PrintParticipantsEvento || (!this.PrintParticipantsEvento && !this.PrintLegalParticipantsEvento)) {
          let request: ReportRequest = this.PreparePartecipantiEventoRequest(ReportTypes.Participants, evento);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
        // stampa solo se è selezionato il report legale
        if (this.PrintLegalParticipantsEvento) {
          let request: ReportRequest = this.PreparePartecipantiEventoRequest(ReportTypes.ParticipantsLegal, evento);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
      }
      if (evento.PrintSintesiVotesReport && this.PrintType == PrintType.Completo) {
        // se è selezionato stampa la sintesi dei risultati
        let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.RealTimePolls, evento, false);
        if (request != null) {
          summaryRequest.push(request);
        }
      }
      if (evento.PrintSintesiVotesReport && this.PrintType == PrintType.Numeri) {
        // se è selezionato stampa la sintesi dei risultati numeri
        let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.RealTimePollsNumber, evento, false);
        if (request != null) {
          summaryRequest.push(request);
        }
      }
      if (evento.PrintSintesiVotesReport && this.PrintType == PrintType.Azioni) {
        // se è selezionato stampa la sintesi dei risultati azioni
        let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.RealTimePollsAction, evento, false);
        if (request != null) {
          summaryRequest.push(request);
        }
      }
      if (evento.PrintDettaglioVotesReport && this.PrintType == PrintType.Completo) {
        if ((!this.StampaRisultati) && !this.StampaAventiDiritto) {
          defaultRisultatiVoto = true;
        }
        // stampa se richiesto o se non è stato indicato nessun tipo di report
        if (this.StampaRisultati || (!this.StampaRisultati && !this.StampaAventiDiritto)) {
          let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.RealTimePolls, evento, true);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
        // stampa solo se richiesto
        if (this.StampaAventiDiritto) {
          let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.ShareholderCanVotePoll, evento, this.ShowVoteDetail);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
      }
      if (evento.PrintDettaglioVotesReport && this.PrintType == PrintType.Azioni) {
        if ((!this.StampaRisultati) && !this.StampaAventiDiritto) {
          defaultRisultatiVoto = true;
        }
        if ((this.StampaRisultati && this.PrintType == PrintType.Azioni) || (!this.StampaRisultati && !this.StampaAventiDiritto)) {
          let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.RealTimePollsAction, evento, true);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
        if (this.StampaAventiDiritto) {
          let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.ShareholderCanVotePoll, evento, this.ShowVoteDetail);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
      }
      if (evento.PrintDettaglioVotesReport && this.PrintType == PrintType.Numeri) {
        if ((!this.StampaRisultati) && !this.StampaAventiDiritto) {
          defaultRisultatiVoto = true;
        }
        if ((this.StampaRisultati && this.PrintType == PrintType.Numeri) || (!this.StampaRisultati && !this.StampaAventiDiritto)) {
          let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.RealTimePollsNumber, evento, true);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
        if (this.StampaAventiDiritto) {
          let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.ShareholderCanVotePoll, evento, this.ShowVoteDetail);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
      }
      if (evento.PrintVoteCardsReport) {
        if (!this.StampaSchedeConsegnate && !this.StampaSchedeRitirate) {
          cantPrintVoteCardReport = true;
        }
        if (this.StampaSchedeConsegnate) {
          let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.VoteCardDelivered, evento);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
        if (this.StampaSchedeRitirate) {
          let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.VoteCardCollected, evento);
          if (request != null) {
            summaryRequest.push(request);
          }
        }
      }
    });

    if (cantPrintVoteCardReport) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Impossibile procedere",
        message: "Non è stato indicato il tipo di report da produrre per le schede di voto, selezionare i report da produrre nella sezione \"Schede di voto\" della colonna \"Impostazioni Report\" a destra.",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
      return;
    }

    if (isNullOrUndefined(summaryRequest) || summaryRequest.length == 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Impossibile procedere",
        message: "Nessun report selezionato per la stampa.",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
      return;
    }

    if (defaultDettaglioPresenti || defaultPartecipanti || defaultRisultatiVoto) {
      let sb: string = "";
      sb += "Saranno prodotti solo i tipi report standard per:";
      if (defaultDettaglioPresenti) {
        sb += "<br/>- dettaglio presenti";
      }
      if (defaultPartecipanti) {
        sb += "<br/>- partecipanti";
      }
      if (defaultRisultatiVoto) {
        sb += "<br/>- risultati voto";
      }
      sb += "<br/>Per selezionare quale tipo di report stampare utilizzare la colonna \"Impostazioni Report\" a destra.";

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Assemblea - Stampa report",
        message: sb,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Information
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
    }

    await this.PrintReportList(summaryRequest);
  }
  private async PrintReportList(summaryRequest: ReportRequest[]) {
    try {
      await this.assembleaService.enqueuePrintSummaryAsync(summaryRequest);

      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella richiesta di stampa dei report",
            message: error.Detail.detail.UIMessage,
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }


  }
  async PreviewPresentiEvento_Click(e) {
    e.preventDefault();
    let evento: vEventoAssemblea = this.SelectedEvent;
    if (isNullOrUndefined(evento)) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Anteprima report",
        message: "Nessun report selezionato per l'anteprima!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
      return;

    }

    if (this.PrintSintesiEvento && this.PrintType == PrintType.Completo) {
      let request: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresents, evento, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
    if (this.PrintSintesiEvento && this.PrintType == PrintType.Azioni) {
      let requestAz: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresentsAz, evento, true);
      if (requestAz != null) {
        await this.SendPrintRequest(requestAz);
      }
    }
    if (this.PrintSintesiEvento && this.PrintType == PrintType.Numeri) {
      let requestNum: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresentsNum, evento, true);
      if (requestNum != null) {
        await this.SendPrintRequest(requestNum);
      }
    }
    if (this.PrintDettaglioEvento) {
      let request: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresentsDetail, evento, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
    if (this.PrintFormatoLegaleEvento) {
      let request: ReportRequest = this.PreparePresentiEventoRequest(ReportTypes.ShareholderPresentsLegal, evento, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
  }
  async PreviewPartecipantiEvento_Click(e) {
    e.preventDefault();
    let evento: vEventoAssemblea = this.SelectedEvent;
    if (isNullOrUndefined(evento)) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Anteprima report",
        message: "Nessun report selezionato per l'anteprima!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
      return;

    }

    if (this.PrintParticipantsEvento) {
      let request: ReportRequest = this.PreparePartecipantiEventoRequest(ReportTypes.Participants, evento, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
    if (this.PrintLegalParticipantsEvento) {
      let request: ReportRequest = this.PreparePartecipantiEventoRequest(ReportTypes.ParticipantsLegal, evento, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
    if (this.ReportPartecipantiType == ReportPartecipantiType.PartecipantiFisici) {
      let request: ReportRequest = this.PreparePartecipantiEventoRequest(ReportTypes.ReportPartecipantiFisici, evento, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
    if (this.ReportPartecipantiType == ReportPartecipantiType.PartecipantiSoci) {
      let request: ReportRequest = this.PreparePartecipantiEventoRequest(ReportTypes.ReportPartecipantiAlbero, evento, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
  }
  async PreviewVotazioniEvento_Click(e) {
    e.preventDefault();
    let evento: vEventoAssemblea = this.SelectedEvent;
    if (isNullOrUndefined(evento)) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Anteprima report",
        message: "Nessun report selezionato per l'anteprima!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
      return;

    }

    if (this.StampaAventiDiritto) {
      let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.ShareholderCanVotePoll, evento, this.ShowVoteDetail, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
    if (this.StampaRisultati && this.PrintType == PrintType.Completo) {
      let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.RealTimePolls, evento, this.ShowVoteDetail, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
    if (this.StampaRisultati && this.PrintType == PrintType.Azioni) {
      let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.RealTimePollsAction, evento, this.ShowVoteDetail, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
    if (this.StampaRisultati && this.PrintType == PrintType.Numeri) {
      let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.RealTimePollsNumber, evento, this.ShowVoteDetail, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
  }
  async PreviewSchedeDiVotoEvento_Click(e) {
    e.preventDefault();
    let evento: vEventoAssemblea = this.SelectedEvent;
    if (isNullOrUndefined(evento)) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Anteprima report",
        message: "Nessun report selezionato per l'anteprima!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
      return;

    }

    if (this.StampaSchedeConsegnate) {
      let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.VoteCardDelivered, evento, true, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
    if (this.StampaSchedeRitirate) {
      let request: ReportRequest = this.PrepareVotazioniEventoRequest(ReportTypes.VoteCardCollected, evento, true, true);
      if (request != null) {
        await this.SendPrintRequest(request);
      }
    }
  }
  //#endregion
}

enum PrintTimeSelection {
  CurrentTime = 0,
  ManualSelection = 1,
  Event = 2,
}

enum PrintDestinationSelection {
  Anteprima = 0,
  Esportazione = 1
}
enum PrintType {
  Azioni = 0,
  Numeri = 1,
  Completo = 2
}
enum ReportPresentiType {
  Sintesi = 0,
  Dettaglio = 1,
  Preregistrati = 2,
  FormatoLegale = 3,
  Deleghe = 4
}

enum ReportCoopType {
  Votanti = 0,
  Voti = 1
}

enum ReportPartecipantiType {
  Partecipanti = 0,
  PartecipantiLegale = 1,
  Registrazioni = 2,
  PartecipantiVoti = 3,
  PartecipantiFisici = 4,
  PartecipantiSoci = 4
}

enum ReportOspitiType {
  Presenti = 0,
  Attesi = 1
}
